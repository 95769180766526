//TODO: move to angular

$(document).ready(function() {
  $(document).delegate("[ngbdatepicker]", "keyup", function(event) {
    var newVal = $(this).val().replace(/[^0-9]/g, '');
    console.log(newVal);

    if (newVal.length < 2) {
      // newVal = newVal.replace(/^(\d{0,3})/, '$1');
      // newVal = '375 ' + (newVal.length == 1 ? newVal : '');
    } else if (newVal.length <= 3) {
      newVal = newVal.replace(/^(\d{0,2})/, '$1.');
    } else if (newVal.length <= 5) {
      newVal = newVal.replace(/^(\d{0,2})(\d{0,2})/, '$1.$2.');
    } else {
      newVal = newVal.substring(0, 8);
      newVal = newVal.replace(/^(\d{0,2})(\d{0,2})(\d{0,4})/, '$1.$2.$3');
    }

    console.log(newVal);
    $(this).val(newVal);
  });

  $('.navbar-collapse .nav-link').click(function () {
    closeNavbar($(this));
  });
});

function closeNavbar(el) {
  if (el.parents('.navbar-collapse').hasClass('show')) {
    $('.navbar-toggler').click();
  }
}

function refreshSelectTitle(selId) {
  setTimeout(function() {
    $('#' + selId).next().find('.filter-option-inner-inner').html($('#' + selId).attr('title'));
  });
}

if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Windows Phone|Opera M/i.test(navigator.userAgent.toLowerCase())) {
  $('html').addClass('mobile');
}
